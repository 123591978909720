import RecommendService from "./RecommendService";
import Contents0 from "./Contents0";
import Contents1 from "./Contents1";
import Contents2 from "./Contents2";
import Contents3 from "./Contents3";
import Typography from "@mui/material/Typography";

import { FadeInView } from "../../../helper/FadeInView";
import { Marquee } from "../../../helper/Marquee";

function Top() {
  return (
    <div>
      <RecommendService />

      {/* <Typography
        variant="h6"
        align="center"
        sx={{ color: "#dddddd" }}
        component="div"
      >
        <Marquee limitWidth={1}>作成中なので信頼しないでね</Marquee>
      </Typography> */}

      {/* <FadeInView>
        <Contents1 />
      </FadeInView> */}

      <FadeInView>
        <Contents2 />
      </FadeInView>

      <FadeInView>
        <Contents0 />
      </FadeInView>

      <FadeInView>
        <Contents3 />
      </FadeInView>
    </div>
  );
}

export default Top;
